@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

@layer base {
	html {
	  font-family: Jalnan, SUIT;
	}
}

/* body {font-family:'Jalnan', 'SUIT'} */


@font-face {
	font-family: 'Jalnan';
	src: local('Jalnan'), url('../assets/fonts/Jalnan.ttf') format('woff');
}


@font-face {
	font-family: 'SUIT';
	font-weight: 900;
	font-display: swap;
	src: local('SUIT Heavy'), url('../assets/fonts/SUIT-Heavy.woff2') format('woff2');
}

@font-face {
	font-family: 'SUIT';
	font-weight: 800;
	font-display: swap;
	src: local('SUIT ExtraBold'), url('../assets/fonts/SUIT-ExtraBold.woff2') format('woff2');
}

@font-face {
	font-family: 'SUIT';
	font-weight: 700;
	font-display: swap;
	src: local('SUIT Bold'), url('../assets/fonts/SUIT-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'SUIT';
	font-weight: 600;
	font-display: swap;
	src: local('SUIT SemiBold'), url('../assets/fonts/SUIT-SemiBold.woff2') format('woff2');
}

@font-face {
	font-family: 'SUIT';
	font-weight: 500;
	font-display: swap;
	src: local('SUIT Medium'), url('../assets/fonts/SUIT-Medium.woff2') format('woff2');
}

@font-face {
	font-family: 'SUIT';
	font-weight: 400;
	font-display: swap;
	src: local('SUIT Regular'), url('../assets/fonts/SUIT-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'SUIT';
	font-weight: 300;
	font-display: swap;
	src: local('SUIT Light'), url('../assets/fonts/SUIT-Light.woff2') format('woff2');
}

@font-face {
	font-family: 'SUIT';
	font-weight: 200;
	font-display: swap;
	src: local('SUIT ExtraLight'), url('../assets/fonts/SUIT-ExtraLight.woff2') format('woff2');
}

@font-face {
	font-family: 'SUIT';
	font-weight: 100;
	font-display: swap;
	src: local('SUIT Thin'), url('../assets/fonts/SUIT-Thin.woff2') format('woff2');
}


/* body {
 
} */
